@import "../../../Style/Config.scss";

.service {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
}

.kiosk-mode .service-option {
  pointer-events: none;
}

.service[href]:hover {
  text-decoration: underline;
}

.service-icon {
  display: flex;
  flex: 0 0 auto;
  height: 30px;
  margin-right: 20px;
  align-items: center;
}

[dir="rtl"] .service-icon {
  margin-right: 0;
  margin-left: 20px;
}

.service-icon img {
  display: inline-block;
  width: 30px;
  height: auto;
}

.service-name {
  flex: 0 0 auto;
}

@include breakpoint(small) {

  .service {
    display: block;
    text-align: center;
  }

  .service-icon {
    margin-right: auto;
    margin-left: auto;
    height: 50px;
    margin-bottom: 10px;
  }

  .service-icon img {
    width: 50px;
  }
}
