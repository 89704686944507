@import "../../../Style/Config.scss";

.servicesSection {
  padding: 30px;
  background-color: $color-offWhite;

  .service {

    &:hover {
      text-decoration: none;
    }
  }
}

.servicesSection p {
  color: $color-midDarkGray;
}

.servicesSection-servicesList {
  margin-top: 30px;
}

.servicesSection-servicesList li {
  display: flex;
}

.servicesSection-servicesList li + li {
  margin-top: 10px;
}

@include breakpoint(small) {

  .servicesSection {
    text-align: center;
  }

  .servicesSection p {
    max-width: 80%;
    margin: 0 auto;
  }

  .servicesSection-servicesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .servicesSection-servicesList li {
    flex: 0 0 20%;
    margin: 20px;
    justify-content: center;
  }

  .servicesSection-servicesList li + li {
    margin-top: 20px;
  }
}

@include breakpoint(large-from) {

  .servicesSection {
    flex: 1 1 auto;
    width: 30%;
    min-width: 280px;
    max-width: 330px;
    padding-top: 60px;
  }

  [dir="rtl"] .servicesSection {
    text-align: right;
  }
}
