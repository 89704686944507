@import "../../../../Style/_Config.scss";

.page-itemlist {
  &--digital {
    padding: 20px;
  }
}

.page-itemlist-printSections {
  .price-breakdown-wrapper {
    padding-bottom: 30px;

    @media print {
      page-break-inside: avoid;
    }
  }

  & .price-breakdown {
    width: 100%;
    float: right;
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
    margin-right: 0;

    &--digital {
      width: 58%;
      padding-right: $spacing-small;

      // stylelint-disable-next-line max-nesting-depth
      [dir="rtl"] & {
        padding-right: 0;
        padding-left: $spacing-small;
      }
    }

    // stylelint-disable-next-line
    @media print {
      page-break-inside: avoid;
    }

    [dir="rtl"] & {
      float: left;
      margin-left: 0;
    }
  }
}

.page-itemlist-header-wrapper {
  min-height: $spacing-small;
  margin-bottom: $spacing-medium;

  &--digital {
    margin-bottom: $spacing-large;
  }
}

.page-itemlist-header {
  font-weight: bold;

  @include print-text-extraLarge;
}
