.switch {
  display: inline-block;
  display: inline-flex;
  position: relative;
  align-items: center;

  &__background {
    position: relative;
    align-items: center;
    display: inline-block;
    display: flex;
    flex-shrink: 0;
    width: 2.5rem;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 0.75rem;
    background-color: #dfdfdf;
    transition: all 0.3s;
    align-self: flex-start;
  }

  &__ball {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #fff;
    transform: translateX(0.25rem);
    align-items: center;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    display: block;
    fill: #666;
    width: .5rem;
    height: .5rem;
    opacity: .6;
  }

  &__label label {
    padding-left: 1rem;
    cursor: pointer;
  }

  input[type="checkbox"] {
    position: absolute;
    top: -0.75rem;
    left: -0.25rem;
    padding: 0;
    border: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    width: 3rem;
    height: 3rem;

    &:checked~.switch__background {
      background: #0058a3;

      .switch__ball {
        transform: translateX(1.25rem);
      }

      .switch__icon {
        fill: #0058a3;
      }
    }

    &:hover~.switch__background {
      background: #ccc;
    }

    &:active~.switch__background {
      background: #959595;
    }

    &:checked:hover~.switch__background {
      background: #3379b5;

      .switch__icon {
        fill: #3379b5;
      }
    }

    &:focus:not(:disabled)~.switch__focus {
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      right: -0.5rem;
      bottom: -0.5rem;
      display: block;
      border: 4px solid #4595ff;
      border-radius: 0.25rem;
    }

    &:checked:active~.switch__background {
      background: #004682;

      .switch__icon {
        fill: #004682;
      }
    }

    &:disabled~ {
      .switch__background {
        background: #f5f5f5;

        .switch__icon {
          fill: #dfdfdf;
        }
      }

      .switch__label label {
        cursor: auto;
      }
    }

    &:checked:disabled~.switch__background {
      background: #99bcda;

      .switch__icon {
        fill: #99bcda;
      }
    }
  }

  +.inline-text {
    margin-top: 0.5rem;
  }

  input[type="checkbox"]:checked~.switch__background {
    background: #0058a3;
  }

  input[type="checkbox"]:checked~.switch__background .switch__ball {
    transform: translateX(1.25rem);
  }

  input[type="checkbox"]:checked~.switch__background .switch__icon {
    fill: #0058a3;
  }

  input[type="checkbox"]:hover~.switch__background {
    background: #ccc;
  }

  input[type="checkbox"]:active~.switch__background {
    background: #959595;
  }

  input[type="checkbox"]:checked:hover~.switch__background {
    background: #3379b5;
  }

  input[type="checkbox"]:checked:hover~.switch__background .switch__icon {
    fill: #3379b5;
  }

  input[type="checkbox"]:focus:not(:disabled)~.switch__focus {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    display: block;
    border: 4px solid #4595ff;
    border-radius: 0.25rem;
  }

  input[type="checkbox"]:checked:active~.switch__background {
    background: #004682;
  }

  input[type="checkbox"]:checked:active~.switch__background .switch__icon {
    fill: #004682;
  }

  input[type="checkbox"]:disabled~.switch__background {
    background: #f5f5f5;
  }

  input[type="checkbox"]:disabled~.switch__background .switch__icon {
    fill: #dfdfdf;
  }

  input[type="checkbox"]:disabled~.switch__label label {
    cursor: auto;
  }

  input[type="checkbox"]:checked:disabled~.switch__background {
    background: #99bcda;
  }

  input[type="checkbox"]:checked:disabled~.switch__background .switch__icon {
    fill: #99bcda;
  }
}