@keyframes popIn {

  from {
    transform: translateX(-100%);
  }
}

@keyframes popOut {

  to {
    transform: translateX(100%);
  }
}

.animate-pop-in {
  animation-name: popIn;
  animation-duration: $animation-default-push-pop-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.animate-pop-out {
  animation-name: popOut;
  animation-duration: $animation-default-push-pop-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
