@import "../../Style/Config.scss";

.special-product-type-information {
  margin-top: $spacing-extraSmall;

  @include paragraph;
}

.special-product-type-family-information {
  font-size: $font-size-base;
  line-height: normal;
  color: $color-ikea-family;
}

.special-product-article-number {
  margin-top: $spacing-extraSmall;

  @include paragraph;
}

.special-product-price-wrapper {
  justify-content: flex-start;
}

.special-product-price {
  display: flex;
  align-items: center;
  margin-top: $spacing-medium;
}

.special-product-price-family-energy {
  margin-top: $spacing-tiny;
  margin-bottom: $spacing-tiny;
}

.special-product-price .price-info {
  text-align: left;

  [dir="rtl"] & {
    text-align: right;
  }
}

.special-product-price-notice {
  font-size: $font-size-base;
}

.special-product-price .price-info.paragraph {
  @include paragraph;
}

.special-product-energy-rating {
  margin-top: $spacing-tiny;
  margin-bottom: $spacing-tiny;
  width: 70px;
  align-self: flex-start;
  animation: slideIn 500ms forwards;
  margin-left: $spacing-small;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: $spacing-small;
  }

}

@keyframes slideIn {

  0% {
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.special-product-price p {
  flex: 0 0 100%;

  @include small;
}

.special-product-description {
  @include paragraph;
}

.special-product-tabs {
  width: 100%;
  margin-top: $spacing-medium;
}

.special-product-dimensions {
  display: block;
  margin-top: $spacing-small;
  padding-top: $spacing-extraSmall;
  padding-bottom: $spacing-extraSmall;
  border-top: 1px solid $color-lightGray;
  border-bottom: 1px solid $color-lightGray;
}

.special-product-description {
  margin-top: $spacing-small;
}
