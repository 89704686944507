@media print {

  body.printingInProgress {

    overflow: auto;

    > div:not(.printElement) {
      display: none;
    }
  }
}

.d-flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}
