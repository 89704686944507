@import "../../Style/Config.scss";

@import "../../Style/Animations/AnimationConfig.scss";

@import "../../Style/Config/BorderSizes.scss";

.projectCard {
  position: relative;
  background-color: $color-white;
  text-align: left;
  transition: $animation-default-ease-in-out-duration border ease-in-out, $animation-default-ease-in-out-duration top ease-in-out;
  border: solid thin $color-lightGray;
  border-radius: $border-default-width;
  margin-top: $spacing-small;
  overflow: hidden;

  [dir="rtl"] & {
    text-align: right;
  }

  &:hover,
  &:active {
    border: solid thin $color-blue;
    border-radius: $border-default-width;
    transition: $animation-default-ease-in-out-duration border ease-in-out, $animation-default-ease-in-out-duration top ease-in-out;
    padding: 0;
  }

  &:hover .projectCard-info {
    border-radius: 0 0 $border-default-width $border-default-width;
  }
}

.projectCard-info {
  padding-top: $spacing-small;
  padding-right: $spacing-small;
  padding-bottom: 15px;
  padding-left: $spacing-small;
}

.projectCard-name-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: $spacing-tiny;

  & > span {
    width: calc(100% - 40px);
    order: 1;
  }
}

.overflowTextEllipsis {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  .overflowTextEllipsis-text-part {
    margin-bottom: $spacing-tiny;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.projectCard-details {
  order: 2;

  button {
    color: $color-textBlack;
  }
}

.projectCard-dates {
  color: $color-darkGray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include small;

}

@include breakpoint(x-large-from) {

  .projectCard-container {
    width: auto;
    max-width: 400px;
  }
}
