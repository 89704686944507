@import "../../Style/Config";

.information-message-body {
  h2 {
    padding-bottom: $spacing-medium;
  }

  p {
    padding-bottom: $spacing-medium;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: $spacing-large;
  margin-left: $spacing-medium;
  margin-right: $spacing-medium;
  padding-top: $spacing-medium;
  padding-bottom: $spacing-large;
  border-bottom: 1px solid $color-lightGray;

  @include breakpoint(x-large-from) {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }

  .information-message-content {
    width: 900px;
  }
}