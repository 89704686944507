@import "../../Style/Config.scss";

.state-error {
  display: block;
  max-width: 500px;
  justify-content: center;
  text-align: center;

  img.general,
  img.browser,
  img.connection,
  img.speed {
    width: 142px;
    height: 152px;
  }

  img.size {
    width: 162px;
    height: 154px;
  }

  h1 {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }

  p + p {
    margin-top: $spacing-medium;
  }

  p .ihp3-link {
    vertical-align: baseline;
  }
}

.state-error-action-button {
  margin-top: $spacing-medium;
  display: flex;
  justify-content: center;

  button {
    margin: $spacing-tiny;
  }
}
