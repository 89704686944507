@import "../../../../Style/Config.scss";

@import "../../PrintConstants.scss";

.print-header {
  width: 100%;

  @media print {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $print-header-height;
  }
}

.header-title {
  top: 0;
}

.header-title h4 {
  @include print-text-extraLarge;
}

.header-project-details .measurements-info {
  @include print-text-small;
}
