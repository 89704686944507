@import "./AnimationConfig.scss";

@keyframes fadeOut {

  to {
    opacity: 0;
  }
}

.animate-fade-out {
  animation-name: fadeOut;
  animation-duration: $animation-default-fade-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
