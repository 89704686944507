/* stylelint-disable */

@font-face {
  font-family: "Malgun";
  src: url("/fonts/MalgunGothicRegular.woff2") format("woff2"),
    url("/fonts/MalgunGothicRegular.woff") format("woff"),
    url("/fonts/MalgunGothicRegular.ttf") format("truetype"),
    url("/fonts/MalgunGothicRegular.svg#MalgunGothicRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Malgun";
  src: url("/fonts/MalgunGothicBold.woff2") format("woff2"),
    url("/fonts/MalgunGothicBold.woff") format("woff"),
    url("/fonts/MalgunGothicBold.ttf") format("truetype"),
    url("/fonts/MalgunGothicBold.svg#MalgunGothicBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Verdana";
  src: url("/fonts/Verdana.woff2") format("woff2"),
    url("/fonts/Verdana.woff") format("woff"),
    url("/fonts/Verdana.ttf") format("truetype"),
    url("/fonts/Verdana.svg#Verdana") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Verdana";
  src: url("/fonts/Verdana-Bold.woff2") format("woff2"),
    url("/fonts/Verdana-Bold.woff") format("woff"),
    url("/fonts/Verdana-Bold.ttf") format("truetype"),
    url("/fonts/Verdana-Bold.svg#Verdana-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
