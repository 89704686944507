*,
*:after,
*:before {
  box-sizing: border-box;
}

:lang(ar) {
  font-family: Tahoma, Helvetica, Arial, sans-serif;
}

:lang(ko) {
  font-family: Malgun, Verdana, Helvetica, Arial, sans-serif;
}

.content-container {
  padding: 1rem;
}

.content-container-card-selector {
  padding-top: 2.5rem;
}

.content-container-gray {
  background-color: #f2f2f2;
}

.content-container-scroll-horizontal {
  overflow-y: auto;
}

.content-container-border-bottom {
  border-bottom: 1px solid #d8d8d8;
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0),
    inset 0 -2px 3px 0 rgba(0, 0, 0, .06);
}

.no-margin {
  margin: 0;
}

.no-scroll {
  overflow: hidden;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

//TODO: Move to ihp: 3 global style;
form {

  .form-element {

    &:not(.last-form-element) {
      margin-bottom: $spacing-medium;
    }
  }
}
