@import "../../../Style/Config.scss";

$exitpoint-boundry: $spacing-large * 4;
$exitpoint-image-boundry: $spacing-large;

$collapseAnimationTime: 200ms;
$imageInitialHeight: 50px;
$medium-from-width: 620px;

@include breakpoint(medium-from) {

  .exitPoint-image {
    position: static;
    display: block;
    flex: 0 0 auto;
    margin-right: $spacing-small;
    margin-left: 0;
    height: 160px;
    width: 160px;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: $spacing-small;
    }
  }
}

.exitPoint-image {
  display: block;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  background-color: #dddddd;
  background-size: cover;
  background-position: center center;

  @include breakpoint(medium-from) {
    width: 198px;
    height: 198px;
  }
}

@include breakpoint(medium-from) {

  .exitPointSection-Launchpage .exitPoint-image {

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: $spacing-large;
    }
  }
}

@include breakpoint(large-from) {

  .exitPointSection-Launchpage .exitPoint-image {

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: $exitpoint-image-boundry;
    }
  }
}

.exitPointSection-Launchpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include breakpoint(medium-from) {
    flex-direction: row;
    justify-content: center;
    text-align: left;
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
    margin: $spacing-medium;
  }

  @include breakpoint(x-large-from) {
    margin: $spacing-large;
  }

  .exitPointSection-content {
    @include breakpoint(medium-from) {
      width: 378px;
    }

    @include breakpoint(medium-from) {
      width: 550px;
    }
  }

  .exitPoint-image {
    height: 120px;
    width: 120px;
    margin-bottom: $spacing-small;

    @include breakpoint(medium-from) {
      height: 180px;
      width: 180px;
      margin-right: $spacing-medium;
      margin-bottom: 0;
    }

    @include breakpoint(large-from) {
      height: 290px;
      width: 290px;
      margin-right: $exitpoint-image-boundry;
    }
  }

  h2 {
    padding-bottom: $spacing-medium;
  }

  p + p {
    padding-top: $spacing-medium;
  }

  a {
    vertical-align: baseline;
  }
}
