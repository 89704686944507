@import "../../../../Style/Config.scss";

@import "../../PrintConstants.scss";

$edge-icon-height: 12px;
$label-spacing: 2.5px;

.worktop-content-frame {
  display: flex;
  height: $image-frame-height;
  flex-direction: column;

  @media print {
    position: relative;
    margin-top: $print-header-height;
    height: $image-frame-height-print;
    display: inline-flex;
    width: 100%;
  }
}

.worktop-information {
  display: flex;

  @include print-text-small;

  @media print {
    display: inline-flex;
  }
}

.worktop-information-column {
  width: 45%;
  flex-shrink: 0;
  float: left;

  @media print {
    display: inline-block;
  }
}

.edge-icon {
  height: $edge-icon-height;
  margin-right: $spacing-tiny;

  @media print {
    float: none;
    display: inline-block;
  }

  [dir="rtl"] & {
    margin-left: $spacing-tiny;
    margin-right: 0;
  }
}

.edge-description {
  width: 90%;
  line-height: $spacing-extraSmall;
  float: left;

  @media print {
    float: none;
    display: inline-block;
  }

  [dir="rtl"] & {
    float: right;
  }
}

.edge-description-title {
  @include print-text-small;
}

.worktop-row {
  @include print-text-small
}

.detail-name {
  font-weight: bold;

  [dir="rtl"] & {
    float: right;
  }
}

.worktop-row-label {
  float: left;
  margin-right: $label-spacing;

  [dir="rtl"] & {
    float: right;
    margin-left: $label-spacing;
    margin-right: 0;
  }
}

.worktop-details {
  margin-right: $spacing-small;

  [dir="rtl"] & {
    margin-right: 0;
    margin-left: $spacing-small;
  }
}

.worktop-details .worktop-row {
  line-height: $print-font-size-medium;
  margin-top: $spacing-tiny;
}

.worktop-edge-description .worktop-row {
  line-height: $print-font-size-medium;
  margin-top: $spacing-tiny;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.worktop-image-frame {
  display: flex;
  flex: 1 1 auto;

  @media print {
    display: inline-flex;
  }
}

.worktop-image-frame .print-image {
  @media print {
    display: inline-flex;
  }
}