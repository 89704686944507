@use "sass:math";

@import "../../../Style/Config.scss";
@import "./ItemListConstants.scss";
@import "./ItemListDigitalConstants.scss";

$side-image-digital-max-width: 120px;
$side-image-print-max-width: 100px;
$side-image-max-width: 80px;
$item-spacing-normal: 8px;
$item-spacing-print: 9pt;
$spacing-medium: 20px;

@mixin side-image-sizing($width) {
  max-width: $width;
  // Layouting is dependent on side images being clamped to a 2:7 aspect ratio.
  max-height: math.div($width * 7, 2);
}

.itemList-printSection-digital {
  padding: 0 $spacing-small;
}

.itemList-printSection {
  margin-bottom: $spacing-medium;

  &--digital {
    padding: 0 $spacing-small;
  }

  span.section-title {
    font-weight: bold;

    @include print-text-medium;
  }

  span.section-subtitle {
    color: $color-midDarkGray;
    margin-left: $spacing-extraSmall;

    @include print-text-small;

    @media print {
      margin-bottom: 1pt;
    }

    [dir="rtl"] & {
      margin-left: 0px;
      margin-right: $spacing-extraSmall;
    }
  }

  .itemList-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: $spacing-extraSmall;
    border-bottom: 1px solid $color-midDarkGray;
    size: 10px;

    @include print-text-small;

    > * {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .itemList-section-listHeaders {
      justify-content: flex-end;
      &--digital {
        width: inherit;
      }
      @media print {
        width: 310px;
      }
    }

    .itemList-section-listHeaders > * {
      text-align: end;
    }

    .itemList-section-listHeaders .itemList-section-articleHeader {
      @include list-column-article;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-article;
      }
    }

    .itemList-section-listHeaders .itemList-section-priceHeader {
      @include list-column-price;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-price;
      }
    }

    .itemList-section-listHeaders .itemList-section-amountHeader {
      @include list-column-amount;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-amount;
      }
    }

    .itemList-section-listHeaders .itemList-section-priceTotalHeader {
      @include list-column-priceTotal;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-priceTotal;
      }
    }
  }

  .itemList-section-content {
    display: flex;
    flex-direction: row;
  }

  .itemList-section-image {
    margin-top: $spacing-extraSmall;
    margin-right: $spacing-extraSmall;
    width: $side-image-print-max-width;

    @media print {
      min-width: $side-image-max-width;
    }

    img {
      object-fit: contain;

      @include side-image-sizing($side-image-print-max-width);

      // stylelint-disable-next-line max-nesting-depth
      @media print {
        @include side-image-sizing($side-image-max-width);
      }
    }

    img:not(:last-of-type) {
      margin-bottom: $spacing-small;
    }

    &--digital {
      margin-top: $spacing-small;
      width: $side-image-digital-max-width;

      img {
        @include side-image-sizing($side-image-digital-max-width);
      }
    }

    [dir="rtl"] & {
      margin-right: 0px;
      margin-left: $spacing-extraSmall;
    }
  }

  .itemList-section-info {
    width: 100%;
  }

  .itemList-section-fillerPieces {
    padding: $spacing-extraSmall 0;
    width: 85%;
    line-height: $print-font-size-medium;

    @include print-text-small;

    &--digital {
      padding: $spacing-small 0;

      @include paragraph;
    }
  }
}

.itemList-item-component {
  padding-top: $item-spacing-normal;
  padding-bottom: $item-spacing-normal;

  &--digital {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  // stylelint-disable-next-line
  @media print {
    padding-top: $item-spacing-print;
    padding-bottom: $item-spacing-print;
  }
}
