
.confirmation .dialog-message {
  position: relative;
  color: $color-blue;
}

.confirmation .dialog-message::before {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  font-family: $icon-font-name;
  font-size: 30px;
  content: $icon-confirm;
  margin-right: .5em;
}

.error .dialog-message {
  position: relative;
  color: $color-red;
}

.error .dialog-message::before {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  font-family: $icon-font-name;
  font-size: 30px;
  content: $icon-error;
  margin-right: .5em;
}

.option {
  padding: 0;
}

.option .option-buttons {
  margin: 0 auto;
}
