@import "./AnimationConfig.scss";

@keyframes fadeInUp {

  from {
    opacity: 0;
    transform: translateY($animation-fade-translate-x);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation-name: fadeInUp;
  animation-duration: $animation-default-fade-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
