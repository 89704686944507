@import "./AnimationConfig.scss";

@keyframes fadeOutDown {

  to {
    opacity: 0;
    transform: translateY($animation-fade-translate-x);
  }
}

.animate-fade-out-down {
  animation-name: fadeOutDown;
  animation-duration: $animation-default-fade-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
