@import "../../../Style/Config.scss";

$searchProjects-width: 300px;

$searchBar-padding-left: 44px;
$searchBar-padding-right: 37px;
$searchBar-padding-top-bottom: 8px;
$searchBar-line-height: 19px;

.search-projects {
  position: relative;


  .search-projects-results {
    position: absolute;
    width: $searchProjects-width;
    background: $color-white;
    border: 1px solid $color-lightGray;
    min-height: 50px;

    .search-result,
    .search-projects-allResults {
      padding-left: $searchBar-padding-top-bottom;
      padding-right: $searchBar-padding-top-bottom;
      line-height: $searchBar-line-height;

      @include paragraph;

    }

    .search-projects-noResults {
      padding-left: $searchBar-padding-top-bottom;
      padding-right: $searchBar-padding-top-bottom;
      line-height: 40px;

      @include paragraph;

    }

    .search-result {
      display: block;
      width: 100%;
      cursor: pointer;
      padding-top: $spacing-extraSmall;
      padding-bottom: $spacing-extraSmall;
      border-bottom: 1px solid $color-lightGray;
      word-break: break-all;
    }

    .search-result:hover {
      background-color: $color-offWhite;
    }

    .search-projects-allResults,
    .search-projects-noResults {
      display: flex;
      justify-content: space-between;
      background-color: $color-offWhite;
      padding-top: $spacing-tiny;
      padding-bottom: $spacing-tiny;
    }

    .search-result-highlight {
      font-weight: bold;
    }

    .search-projects-loader {
      height: $spacing-medium;
      width: $spacing-medium;
    }
  }
}

.search-projects-input {
  min-width: $searchProjects-width;
  position: relative;

  input {
    width: 100%;
    padding-left: $searchBar-padding-left;
    padding-right: $searchBar-padding-right;
    padding-top: $searchBar-padding-top-bottom;
    padding-bottom: $searchBar-padding-top-bottom;
    line-height: $searchBar-line-height;
    border: 1px solid $color-lightGray;
    font-family: inherit;

    @include paragraph;

    /* Magic number required to move search input below tabs and expand tabs */
    @media only screen and (min-width: 960px) {
      border: 0;
    }

    // Removes the regular clear button for IE and Edge
    &::-ms-clear {
      display: none;
    }
  }

  div {
    padding-left: $searchBar-padding-left;
    padding-right: $searchBar-padding-right;
    height: 0px;
    visibility: hidden;

    @include paragraph;
  }

  .searchIcon {
    position: absolute;
    top: $searchBar-padding-top-bottom;
    left: $spacing-extraSmall;
  }

  .ihp3-link {
    position: absolute;
    top: $searchBar-padding-top-bottom;
    right: $spacing-extraSmall;
  }
}
