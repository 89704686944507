@import "../../../Style/_Config.scss";

.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: $print-font-size-small;

  &--digital {
    line-height: $print-font-size-large;
  }
}

.price-flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.price-info-asterisk {
  position: absolute;
  right: $asterisk-offset;

  &--digital {
    right: $asterisk-offset-digital;
  }
}

.price-info-asterisks {
  position: absolute;
  right: $asterisks-offset;

  &--digital {
    right: $asterisks-offset-digital;
  }
}

.price-total-text {
  display: table;
  font-weight: bold;

  @include print-text-medium;
}

.price-breakdown-price-summary-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1 auto;
  font-size: $font-size-base
}

.price-breakdown-regular-price {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.price-family-text {
  display: table;
  font-weight: bold;
  font-size: $font-size-base;
  margin-top: $spacing-tiny;

  @include print-text-medium;
}

.price-family-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1 auto;
}

.price-row {
  margin-top: $spacing-tiny;
}

.price-final-row {
  margin: $spacing-tiny 0;

  div {
    display: inline-block;
    font-size: $font-size-large-price-print;
    white-space: nowrap;

    @media print {
      font-size: 1.1em;
    }
  }

  .secondary-smaller-price div {
    font-size: 75%;
  }

  &--digital div {
    font-size: $print-font-size-large;
  }
}

.price-regular-row {
  display: table;
  margin-top: $spacing-tiny;

  @include print-text-extraSmall;

  &--digital {
    @include print-text-small;
  }
}

.price-vat {
  text-align: end;

  @include print-text-extraSmall;
}

.price-details-regular {
  display: flex;
  justify-content: flex-end;
  font-size: $print-font-size-extraSmall;

  &--digital {
    font-size: $font-size-base;
  }
}

.price-details {

  p {
    font-weight: bold;
    margin-bottom: $spacing-extraSmall;
    text-align: start;

    @include print-text-small;
  }

  &--digital p {
    @include paragraph;
  }
}

// the price-details is a table row that has 4 columns that are spans
// the 1st is the group label
// the 2nd is the regular price if there is a discount or an empty span otherwise
// the 3rd is the actual price (regular/discounted) or empty if there are family prices but not in this row
// the 4th is * if there are discounts and a notice has to be shown
.price-details {
  border-top: 1px solid $color-lightGray;
  margin-top: $spacing-extraSmall;
  padding-bottom: $spacing-extraSmall;

  @media print {
    margin-top: $spacing-small;
  }

  span {
    @include print-text-small;

    &:first-child {
      flex-basis: 50%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      flex-basis: 25%;
      text-align: end;
    }

    &:last-child {
      flex-basis: 2%;
      text-align: end;

      // stylelint-disable-next-line max-nesting-depth
      @media print {
        flex-basis: 1.5%;
      }
    }
  }

  &--digital span {
    @include paragraph;
  }
}

.price-details-border {
  border-bottom: 1px solid $color-lightGray;
}

.price-breakdown {
  flex: auto;
}

.price-notice {
  font-style: italic;

  @include print-text-extraSmall;
}

.price-family-color {
  color: $color-ikea-family;
}

.price-expired-info {
  color: $color-red;
  margin-top: $spacing-extraSmall;

  @include print-text-small;
  @media print {
    margin-top: 0;
  }
}

.price-expired-svg {
  width: 10px;
  height: 10px;
  margin-right: $spacing-tiny;
  margin-left: 0;
  fill: $color-red;

  [dir="rtl"] & {
    margin-right: 0;
    margin-left: $spacing-tiny;
  }
}

.price-expired-cover {
  text-align: center;
  color: $color-red;
  margin: 5% 10% 5% 10%;

  @include print-text-medium;

  & .price-expired-div {
    margin-bottom: $spacing-extraSmall;
    font-size: $print-font-size-small;
    line-height: $print-font-size-medium;
  }

  & .price-expired-svg {
    margin: 0;
    margin-bottom: $spacing-extraSmall;
    width: 25px;
    height: 25px;
  }
}

.priceDisplay-item-list {
  font-size: 1.125rem;

  &--digital {
    > div {
      font-size: 1.0625rem;
      line-height: 1.4375rem;
      font-weight: bold;

      @include breakpoint(medium) {
        font-size: 1.25rem;
        line-height: 1.5625rem;
      }

      @include breakpoint(large) {
        font-size: 1.25rem;
        line-height: 1.5625rem;
      }

      @include breakpoint(x-large) {
        font-size: 1.625rem;
        line-height: 1.9375rem;
      }
    }
  }
}

.price-disclaimer-notice {
  text-align: end;
  font-size: 6px;

  &--digital {
    font-size: 9px;
  }
}

.selenium-price-expired .expired-article-info {
  font-size: 0.75rem;

  @media print {
    font-size: 0.4375rem;
  }
}

.price-includes {
  font-weight: bold;
  margin-bottom: $spacing-extraSmall;
  margin-top: $spacing-extraSmall;
  @include print-text-small;

  @media print {
    margin-top: $spacing-small;
  }
}
