@import "../../Style/Config.scss";

.notification-modal-wrapper {
  height: auto;
  width: auto;
  width: 355px;
  text-align: center;
}

.notification-modal-icon {
  padding: $spacing-small;
  user-select: none;
}

.notification-modal-icon .red {
  color: $color-red;
}

.notification-modal-throbber {
  display: flex;
  margin: 0 auto;
}

.notification-modal-throbber-loading::before {
  visibility: hidden;
}

.notification-modal-content {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;

  @include paragraph;
}

.notification-modal-dismiss-button {
  line-height: 40px;
  font-weight: bold;
  height: 60px;
  width: 70%;
  padding: auto;
}
