@import "../../Style/Config.scss";

$option-content-max-width: 300px;

.dialog-container {
  padding: 40px;
  text-align: center;
}

.dialog-container h3 {
  text-align: center;
  margin: 0;
}

.dialog-container .dialog-message {
  margin-top: $spacing-small;
  margin-bottom: $spacing-medium;
}

.dialog-image {
  margin-bottom: $spacing-small;
}

.option-buttons .form-element button {
  width: 100%;
  max-width: $option-content-max-width;
  margin: $spacing-tiny 0;
}

.option-buttons .form-element:first-of-type {
  margin-top: $spacing-small;
}

.option {

  h3 {
    max-width: $option-content-max-width;
    margin: 0 auto;
  }

  .dialog-message {
    max-width: $option-content-max-width;
  }
}
