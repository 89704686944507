$button-padding-vertical: 10px;
$button-padding-horizontal: 30px;

.button {
  display: inline-block;
  text-decoration: none;
  font-size: 1rem;
  padding: $button-padding-vertical;
  padding-left: $button-padding-horizontal;
  padding-right: $button-padding-horizontal;
  border-radius: 3px;
  line-height: 1;
  transition: 100ms all ease-in-out;
  border: 0;
  cursor: pointer;
  user-select: none;
}

.button img {
  display: inline-block;
  vertical-align: text-bottom;
  height: 18px;
  width: auto;
}

.button:disabled {
  cursor: default;
}

/**
 * Primary button (blue)
 */
.button-primary {
  background-color: $color-blue;
  color: $color-white;
}

.button-primary:not(:disabled):hover {
  background-color: $color-darkBlue;
}

/**
 * Block level button
 */
.button-block {
  display: block;
  width: 100%;
  text-align: center;
}

/**
 * Secondary button style (gray with orange hover)
 */
.button-secondary {
  background-color: $color-lightGray;
  color: $color-white;
}

.button-secondary:not(:disabled):hover {
  background-color: $color-orangeLight;
  color: $color-white;
}

/**
 * Transparent button style
 */
.button-ghost,
.button-ghost:not(:disabled):hover {
  background: transparent;
  border: 1px solid $color-blue;
  color: $color-blue;
  padding: calc(#{$button-padding-vertical} - 1px);
  padding-left: calc(#{$button-padding-horizontal} - 1px);
  padding-right: calc(#{$button-padding-horizontal} - 1px);
}

/**
 * White border and content.
 */
.button-white {
  border: 1px solid white;
  color: white;
}

.button-white-background {
  background-color: #ffffff;
  color: #3365a1;
}

/**
 * Red transparent button style
 */
.button-ghost-red,
.button-ghost-red:not(:disabled):hover {
  background: transparent;
  border: 1px solid $color-red;
  color: $color-red;
  padding: calc(#{$button-padding-vertical} - 1px);
  padding-left: calc(#{$button-padding-horizontal} - 1px);
  padding-right: calc(#{$button-padding-horizontal} - 1px);
}

/**
 * Link styled button (only text)
 */
.button-link {
  background: none;
  border: 0;
  color: $color-blue;
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

.button-link:hover {
  text-decoration: underline;
}

/**
 * Black link styled button (only text)
 */
.button-link-black {
  background: none;
  border: 0;
  color: $color-black;
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
}

/**
 * Navigation button
 */
.button-navigate {
  background: none;
  border: 0;
  color: $color-midDarkGray;
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

.button-navigate:hover {
  text-decoration: underline;
}

/**
 * Back navigation button
 */
.button-back::before {
  display: inline-block;
  font-family: $icon-font-name;
  content: $icon-caret-left;
  margin-right: $spacing-tiny;
  margin-left: 0;
}

[dir="rtl"] .button-back::before {
  content: $icon-caret-right;
  margin-right: 0;
  margin-left: $spacing-tiny;
}

/**
 * Forward navigation button
 */
.button-forward::before {
  display: inline-block;
  font-family: $icon-font-name;
  content: $icon-caret-right;
  margin-right: 0;
  margin-left: $spacing-tiny;
}

[dir="rtl"] .button-forward::before {
  content: $icon-caret-left;
  margin-right: $spacing-tiny;
  margin-left: 0;
}

/**
 * X button for modal (close)
 */
.button-close-icon {
  width: 28px;
  height: 28px;
  padding: 5px;
  background: transparent;
  color: transparent;
  font-size: 0;
}

.button-close-icon::before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: $icon-close;
  font-family: $icon-font-name;
  color: $color-darkGray;

  @include paragraph;
}

.selenium-print-modal-close.button-close-icon::before {
  color: $color-lightGray;
}

/**
 * X button (close)
 */
.button-close {
  padding: 0;
  background: transparent;
  color: transparent;
}

.button-close::before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: $icon-close;
  font-family: $icon-font-name;
  color: #000000;
}
