@import "../../Style/Config.scss";

.tab-content-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tab-content-main.tab-content-small {
  width: 33%;
}

.tab-content-main {
  width: 65%;
  padding-right: $spacing-small;

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $spacing-small;
  }
}

.tab-content-text {
  margin-bottom: $spacing-small;

  @include paragraph;

  span {
    display: block;
  }

  span + span {
    margin-top: $spacing-extraSmall;
  }
}

.tab-content-more-info {
  margin-top: $spacing-extraSmall;
}

.tab-content-side {
  width: 35%;
  padding-left: $spacing-small;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: $spacing-small;
  }
}

.tab-content-side-wide {
  width: 65%;
}

.tab-content-measurements-heading {
  @include leadParagraph;
}

.tab-content-measurements-table {
  line-height: 1.25rem;
}

.tab-content-measurements-table td {
  width: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

.tab-content-measurements-table td + td {
  white-space: nowrap;
}

.tab-content-measurements-table td:first-of-type {
  padding-right: $spacing-medium;

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $spacing-medium;
  }
}

.tab-content-headline {
  display: block;
  font-weight: bold;
}

.special-product-info-tab-energy .tab-content-text {
  display: flex;
  padding-top: $spacing-extraSmall;
  padding-bottom: $spacing-extraSmall;
  margin-bottom: 0;
  font-weight: bold;

  + .tab-content-text {
    border-top: 1px solid $color-lightGray;
  }

  strong {
    width: 50%;
    font-weight: normal;
    margin-right: $spacing-medium;

    [dir="rtl"] & {
      margin-left: $spacing-medium;
      margin-right: 0;
    }
  }
}
