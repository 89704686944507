@import "../../../../../Style/Config.scss";

@import "../../../ItemList/ItemListConstants.scss";
@import "../../../ItemList/ItemListDigitalConstants.scss";

$item-spacing-normal: 8px;
$item-spacing-print: 9pt;
$spacing-medium: 20px;

.itemNote-printSection {
  margin-bottom: $spacing-medium;

  span.section-title {
    font-weight: bold;
    @include print-text-medium;
  }

  .itemNote-section-content {
    display: flex;
  }

  .itemNote-section-info {
    width: 100%;
  }

  .itemNote-section-itemWrapper {
    padding-bottom: 10px;
  }
}

.itemNote-item-component {
  padding-top: $item-spacing-normal;
  padding-bottom: $item-spacing-normal;

  &--digital {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  // stylelint-disable-next-line
  @media print {
    padding-top: $item-spacing-print;
    padding-bottom: $item-spacing-print;
  }
}
