@import "../../../Style/Config.scss";

$buttonMaxWidth: 220;

.hiddenElement {
  visibility: hidden;
}

.responsive-wrapper {
  @include breakpoint(medium-from) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .project-details-left {
      width: 60%;
      margin-right: $spacing-medium;
    }

    [dir="rtl"] .project-details-left {
      margin-right: 0;
      margin-left: $spacing-medium;
    }

    .project-details-right {
      width: 40%;
    }
  }

  @include breakpoint(large-from) {
    flex-direction: column;
    justify-content: flex-start;

    .project-details-left,
    .project-details-right {
      width: auto;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .itemList-load-indicator {
    text-align: center;
    padding-bottom: $spacing-small;
    position: relative;

    .loader {
      display: inline-block;
      position: relative;
    }
  }
}

.project-title {
  display: inline-block;
  word-break: break-all;
  letter-spacing: -0.06rem;

  .ihp3-link {
    padding-left: $spacing-extraSmall;

    [dir="rtl"] & {
      padding-right: $spacing-extraSmall;
      padding-left: 0;
    }
  }
}

.project-disclaimer-wrapper {
  padding: 0;
}

.project-page-information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .button-item-list {

    margin-top: $spacing-small;
    margin-bottom: $spacing-small;

    @include paragraph;

    img {
      vertical-align: middle;
    }
  }
}

.project-details {
  padding-bottom: $spacing-medium;

  @include breakpoint(large-from) {
    padding-bottom: $spacing-medium;
  }

  span {
    font-weight: bold;
  }

  p {
    @include small;
  }
}

.project-description {
  word-break: break-all;
  padding-top: $spacing-extraSmall;

  @include paragraph;

  @include breakpoint(x-large-from) {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.project-date-information {

  margin-top: $spacing-small;
  padding-bottom: $spacing-small;

  p {
    margin-bottom: 0;

    @include small;

  }
}

.screen-size-warning {
  margin-top: $spacing-small;
  padding-bottom: $spacing-small;

  p {
    @include small;
  }
}

.price-wrapper {
  @include leadParagraph;

  h2 {
    margin-bottom: $spacing-tiny;
  }

  .total-price-label {
    font-size: .875rem;
    font-weight: normal;
  }

  >div>span {
    padding-top: $spacing-tiny;
    font-weight: normal;
    display: inline-block;
    width: 100%;
    text-align: right;

    [dir="rtl"] & {
      text-align: left;
    }
  }
}

.project-information-productList {
  padding-bottom: $spacing-small;
}

.project-information-productList::before {
  margin-right: $spacing-extraSmall;

  [dir="rtl"] & {
    margin-right: 0;
    margin-left: $spacing-extraSmall;
  }
}

.product-list-icon {
  padding-right: 10px;
  margin-top: 10px;
}

[dir="rtl"] .product-list-icon {
  padding-left: 10px;
  padding-right: 0;
}

.price-vat-display {
  font-size: 1rem;
  margin-bottom: 10px;

  @include small;
}

.seperator-vat-itemlist {
  padding-bottom: $spacing-small;
}

.project-control-wrapper {
  @include breakpoint(x-large-from) {

    display: flex;
    flex-direction: column;
  }
}

.project-details-wrapper {
  flex-grow: 1;
  justify-content: space-between;
}

.project-details-left .price-info-timeRestrictedOffer,
.project-details-left .price-info-family {
  text-align: left;
  margin-bottom: $spacing-small;
}

.project-control-wrapper .button-primary {
  max-width: $buttonMaxWidth + px;
  margin-top: $spacing-small;
  margin-bottom: $spacing-extraSmall;

  @include breakpoint(large-from) {

    margin-top: 0;
    margin-bottom: $spacing-extraSmall;
  }
}

.project-control-wrapper .ihp3-button {
  margin-bottom: $spacing-extraSmall;
}

.copy-project-modal {
  font-weight: bold;
  text-align: center;
  padding: 40px;
}

.open-project-list-modal {
  font-weight: bold;
  text-align: center;
  padding: 40px;
}

.button.price-expander-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.price-expander-button {
  display: flex;
  justify-content: space-between;
  padding-top: $spacing-tiny;
  width: 100%;
}

.price-icon::before {
  transition: transform 400ms;
}

.price-icon-rotate::before {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.project-priceBreakdown-expand {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: max-height .3s ease-in;
  width: calc(100% - #{$asterisks-offset-digital});
  padding-right: -$asterisks-offset-digital;
}

.project-price-disclaimer-notice {
  font-size: $font-size-base;

  >div {
    padding-top: $spacing-tiny;
  }
}

.price-notice {
  font-size: $font-size-base;
  font-style: normal;
  justify-content: flex-start;
}

.total-price {
  font-size: 20px;
  font-weight: bold;
}

.customer-cancel-validation-notification {
  font-size: $font-size-base;
  margin: 3px 0;
}
