@import "../../Style/Config.scss";

.card {
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .2);
  border-radius: 2px;
  overflow: hidden;
  color: #000000;
  border: 0;
  transition: 50ms border;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.card-selected {
  border: 3px solid $color-blue;
}

.card-media {
  position: relative;
  text-align: center;
}

.card-media-image {
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-content {
  text-align: center;
  padding: $spacing-small;

  p, div {
    width: 100%;
  }

}

.card-title {
  font-weight: bold;
  margin-bottom: $spacing-extraSmall;
}
