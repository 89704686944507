@import "../../../Style/Config.scss";

.snapshot {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  border: solid thin $color-lightGray;

  img {
    width: 100%;
    height: auto;
  }
}

.smallprint-label {
  position: absolute;
  color: $color-midDarkGray;
  background-color: $color-white;
  margin-top: $spacing-tiny;
  margin-left: $spacing-tiny;
  padding: $spacing-extraSmall;

  @include small;
}

.slideshow-wrapper {
  display: flex;

  .slider {
    display: flex;
    white-space: nowrap;
    transition: all 700ms;
  }

  .button-wrapper {
    display: flex;
    margin-top: $spacing-small;
    cursor: pointer;
  }

  .scrollButtons {
    background: none;
    border: 0;
    padding-bottom: $spacing-extraSmall;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }

  .thumbnail-wrapper {
    display: inline-block;
    overflow-x: hidden;
    white-space: nowrap;
    transition: all 7ms;

    @include breakpoint(x-large-from) {
      width: auto;
    }
  }

  .thumbnail-section-title {
    flex: 0 0 100%;
    color: $color-midDarkGray;
    text-align: left;
    margin-bottom: $spacing-tiny;

    @include small;

    [dir="rtl"] & {
      text-align: right;
    }
  }

  .thumbnail {
    display: inline-block;
    margin-right: $spacing-extraSmall;
    outline: 0;
    transition: none;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: $spacing-extraSmall;
    }

    img {
      border: 1px solid $color-lightGray;
      max-height: $spacing-large;
      width: 110px;
      height: auto;
    }
  }

  .active-thumbnail {
    border-bottom: 2px solid $color-black;
    padding-bottom: $spacing-tiny;
    border-radius: 0;
  }

}
