@import "../../../../Style/Config.scss";

$icon-size: 20px;

.print-preview-error {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: $spacing-medium;
}

.print-preview-error-center-wrapper {
  max-width: 100%;
  height: auto;

  p {
    margin: $spacing-small;
    font-weight: bold;

    @include print-text-large;
  }
}

.print-preview-error-try-again svg {
  fill: $color-blue;
  height: $icon-size;
  width: $icon-size;
  margin-right: $spacing-extraSmall;
}

[dir="rtl"] .print-preview-error-try-again svg {
  margin-left: $spacing-extraSmall;
  margin-right: 0px;
}
