@use "sass:math";

@import "../../Style/Config.scss";

.price-breakdown-table {
  table-layout: auto;
  width: 100%;
  border-bottom: 1px solid $color-lightGray;
  margin-bottom: 1rem;

  @include fontVerdana;

  .collapsed-td-state {
    padding-top: $spacing-extraSmall;
    padding-bottom: $spacing-extraSmall;
  }

  .vertical-aligned {
    vertical-align: middle;
  }

  tr {
    border-top: 1px solid $color-lightGray;
  }

  td {
    text-align: right;

    padding-top: $spacing-extraSmall + $spacing-tiny;
    padding-bottom: $spacing-extraSmall + $spacing-tiny;

    [dir="rtl"] & {
      text-align: left;
    }
  }

  td + td {
    padding-left: $spacing-small;

    [dir="rtl"] & {
      padding-left: 0;
      padding-right: $spacing-small;
    }
  }

  td:first-child {
    text-align: left;

    [dir="rtl"] & {
      text-align: right;
    }
  }

  tfoot .price-vat {
    white-space: nowrap;

    @include small;
  }

}

.price-breakdown-table .price-group-title:first-child {
  border-top: 0;
}

.price-breakdown-table .price-group-title:not(:first-child) td {
  padding-top: $spacing-large;
}

.price-breakdown-table .price-group-title td {
  font-weight: bold;
}

.price-breakdown-table-total-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
  }
}

.price-breakdown-article-group .expandable-content {
  @include small;
}

button.price-breakdown-article-group-header {
  display: flex;
  padding-top: math.div($spacing-tiny, 2);
  padding-bottom: math.div($spacing-tiny, 2);

  @include fontVerdana;
}

.price-breakdown-expandable-button {
  margin-left: $spacing-extraSmall;
  transition: transform .2s ease-in-out;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: $spacing-extraSmall;
  }

  &:focus {
    outline: 0;
  }
}

.rotated-price-breakdown-expandable-button {
  transform: rotate(-180deg);
}

.total-price {
  div {
    font-size: 20px;
  }
}

.total-regular-price {
  align-items: center;
  font-weight: normal;

  div {
    font-size: $font-size-base;
  }
}

.price-info {
  text-align: center;

  @include small;
}

.price-info + .price-info {
  margin-top: $spacing-extraSmall;
}

.price-info + .price-breakdown-table {
  margin-top: $spacing-small;
}

.price-info-family {
  color: $color-ikea-family;
  font-size: $font-size-base;
}

.column-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.price-info-breakdown-asterisk {
  position: absolute;
  right: $asterisk-offset-digital;
}

.price-info-breakdown-asterisks {
  position: absolute;
  right: $asterisks-offset-digital;
}

.project-page-information tfoot {
  display: none;
}

.project-page-information .price-with-breakdown-container {

  .price-info-timeRestrictedOffer,
  .price-info-family {
    display: none;
  }
}

.price-breakdown-disclaimer-notice {
  font-size: $font-size-base;
  padding-top: $spacing-medium;
  text-align: right;
}

.white-space-nowrap{
  white-space: nowrap;
}
