@import "../../../Style/Config.scss";

/* Magic number required to place search field inside Tabs */
$searchWrapperMarginTop: -3.6rem;

.projectList-tabs-search-wrapper {
  @media only screen and (min-width: 960px) {
    position: relative;
  }
}

.projectList-filter-tabs {
  margin-bottom: $spacing-small;
}

.projectList-search-projects {
  display: flex;
  justify-content: space-around;

  /* zIndex to place search result dropdown higher than project cards */
  z-index: 90;

  @media only screen and (min-width: 960px) {
    justify-content: flex-start;
    display: block;
    position: absolute;
    margin-top: $searchWrapperMarginTop;
  }
}
