@import "../../Style/Config.scss";

@import "../../Style/Animations/AnimationConfig.scss";

.newProjectCard-container {
  margin-top: $spacing-small;
  position: relative;
}


.newProjectCard-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 40%;
    height: 40%;
    margin-bottom: $spacing-medium;
    margin-top: $spacing-small;
    transition: $animation-default-ease-in-out-duration fill ease-in-out, $animation-default-ease-in-out-duration top ease-in-out;
    fill: $color-lightGray;
  }

  > * {
    margin-left: auto;
    margin-right: auto;
  }
}

.newProjectCard-component .new-project-button-icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.newProjectCard-component .ihp3-button.button-secondary {
  align-self: center;
}

.newProjectCard-component:hover,
.newProjectCard-component.active {

  svg {
    transition: $animation-default-ease-in-out-duration fill ease-in-out, $animation-default-ease-in-out-duration top ease-in-out;
    fill: $color-blue;
  }
}
