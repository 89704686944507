@import "../../../Style/Config.scss";

.projectCard-overlay {
  background: rgba($color-black, .3);
  position: fixed;
  height: 100%;
  width: calc(100% - 1px);
  bottom: 0;
  left: 0;
  z-index: 2;
}

.projectCard-overlay-container {
  background: $color-white;
  height: auto;
  width: 100%;
  position: fixed;
  bottom: 0;
  transition: 100ms all;
}

.delete-project-title {
  margin-bottom: $spacing-small;
}

.projectCard-overlay .projectCard-overlay-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.projectCardOverlay-inputs {
  width: 100%;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

.projectCardOverlay-inputs.rename form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.projectCardOverlay-inputs.rename input {
  width: 80%;
  display: inline-block;
  font-size: 1.2rem;
  padding-bottom: $spacing-tiny;
  font-weight: bold;
  border: 0;
  outline: 0;
  background: transparent;
}

.projectCardOverlay-inputs.rename .ihp3-input input[type="text"]:focus {
  box-shadow: none;
}

.projectCard-overlay-close {
  position: absolute;
  margin-right: $spacing-extraSmall;
  margin-top: $spacing-extraSmall;
  top: 0;
  right: 0;
}

.projectCardOverlay-buttons {
  width: 100%;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
  word-break: break-all;
}

.projectCardOverlay-buttons .ihp3-button + .ihp3-button {
  margin-left: 0;
}

.projectCardOverlay-buttons .button:not(.button-link) {
  width: 100%;
  height: 43px;
}

.projectCardOverlay-buttons .rename-button {
  height: auto;
  min-height: 44px;
  padding-top: $spacing-extraSmall;
  padding-bottom: $spacing-extraSmall;
}

.projectCardOverlay-buttons .delete-button, .cancel-rename-button {
  margin-top: $spacing-small;
}

.confirm-rename-button {
  margin-top: $spacing-small*2;
}

.projectCardOverlay-buttons .button.default-button:hover {
  background: #fafafa;
}

.projectCardOverlay-buttons .button {
  font-size: .9rem;
}

.projectCardOverlay-buttons .button-margin {
  margin-top: $spacing-small;
  justify-content: center;
}

.projectCardOverlay-error p {
  font-size: .9rem;
  margin-top: 15px;
  margin-bottom: $spacing-small;
}

.projectCardOverlay-error h6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.projectCardOverlay-error h6 .icon {
  color: $color-red;
}

.projectCardOverlay-error img {
  height: 18px;
  margin-left: $spacing-tiny;
  margin-right: $spacing-tiny;
}

@include breakpoint(medium-from) {

  .projectCard-overlay {
    height: 100%;
    position: absolute;
  }

  .projectCard-overlay-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
  }

  .projectCardOverlay-inputs.rename form {
    display: flex;
    flex-direction: column;
  }

  .projectCardOverlay-inputs.rename .ihp3-link {
    visibility: hidden;
  }

  .projectCardOverlay-inputs.rename input {
    width: 100%;
    border-bottom: 1px dashed $color-lightGray;
  }
}
