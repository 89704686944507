@import "../../Style/Config.scss";

.shimmer {
  display: block;
  background: $color-offWhite;
  background-image: linear-gradient(to right, $color-offWhite 0%, rgba($color-lightGray, .2) 50%, $color-offWhite 100%);
  background-repeat: no-repeat;
  background-size: 50% 100%;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {

  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
