@import "./AnimationConfig.scss";

@keyframes fadeIn {

  from {
    opacity: 0;
  }
}

.animate-fade-in {
  animation-name: fadeIn;
  animation-duration: $animation-default-fade-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
