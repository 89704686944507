$digitalList-column-priceTotal-width: 150px;
$digitalList-column-amount-width: 65px;
$digitalList-column-price-width: 150px;
$digitalList-column-article-width: 90px;

$digitalList-column-print-width-factor: 1.5;

@mixin digitalList-column-sizing($baseWidth) {
  width: $baseWidth;

  @media print {
    width: #{$baseWidth * $list-column-print-width-factor};
  }
}

@mixin digitalList-column-priceTotal {
  @include digitalList-column-sizing($digitalList-column-priceTotal-width);
}

@mixin digitalList-column-amount {
  @include digitalList-column-sizing($digitalList-column-amount-width);
}

@mixin digitalList-column-price {
  @include digitalList-column-sizing($digitalList-column-price-width);
}

@mixin digitalList-column-article {
  @include digitalList-column-sizing($digitalList-column-article-width);
}
