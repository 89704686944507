@import "../../../../Style/Config.scss";

@import "../../PrintConstants.scss";

.print-text {
  position: relative;
  margin-top: $spacing-small;
  text-align: justify;
  word-break: break-word;
  line-height: 13px;
  white-space: pre-wrap;

  @include print-text-medium;

  @include line-spacing-print-large;

  @media print {
    top: $spacing-medium;
  }

  &.measurement {
    color: white;
  }
}
