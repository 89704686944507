@import "../../Style/Config.scss";

@include breakpoint(medium-from) {

  .project-services-section-wrapper  .servicesSection-servicesList li .service {
    flex-direction: column;
  }

  .project-services-section-wrapper  .servicesSection-servicesList li .service-icon {
    display: flex;
    justify-content: center;
    height: $spacing-large;
    width: 100%;
    margin: 0;
    align-items: center;
  }

  .project-services-section-wrapper  .servicesSection-servicesList li .service-icon img {
    margin-bottom: $spacing-small;
    width: 50px;
  }
}

@include breakpoint(large-from) {

  .project-services-section-wrapper  .servicesSection-servicesList li .service:nth-child(-n+3) {
    margin-bottom: $spacing-medium;
    margin-top: $spacing-medium;
  }

  .project-services-section-wrapper  .servicesSection-servicesList li .service:nth-child(3n+2) {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
}

.project-services-section-wrapper {
  margin-top: $spacing-medium;
  padding-top: $spacing-medium;
  margin-left: $spacing-medium;
  margin-right: $spacing-medium;
  width: 100%;

  @include breakpoint(x-large-from) {
    border-top: 0;
  }

  p {
    margin-left: 0;
    margin-right: 0;
    color: $color-black;
  }


  .servicesSection {
    background-color: $color-white;
    padding: 0;
    min-width: 100%;

    @include breakpoint(medium-from) {
      text-align: center;
      margin: 0 auto;
      min-width: 510px;
    }

    p {
      margin-top: $spacing-small;
      margin-bottom: $spacing-medium;
    }

  }

  .servicesSection-servicesList {
    @include breakpoint(medium-from) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
    }
  }

  .servicesSection-servicesList li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include breakpoint(medium-from) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 150px;
      min-width: 150px;
      margin: 0;

    }

    &:hover {
      outline: 2px solid $color-blue;
      border-radius: 5px;
    }

    span {
      @include paragraph;
    }

    .service-icon {
      display: inline-flex;
      margin-right: 0;
    }

    [dir="rtl"] .service-icon {
      margin-right: 0;
      margin-left: 0;
    }

    .service-icon img {
      height: 100%;
    }
  }
}
