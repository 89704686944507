@import "../../../../Style/Config.scss";

@import "../../PrintConstants.scss";

// Image
.page-cover .print-image-wrapper {
  overflow: hidden;
  margin-bottom: $spacing-medium;

  @media print {
    height: 25%;
  }
}

.page-cover .print-image {
  width: 540px;
  height: 300px;
  object-fit: cover;

  @media print {
    position: relative;
    width: 540pt;
    min-height: 300pt;
    vertical-align: middle;
    display: inline-block;
    object-fit: cover;
  }
}


.page-cover .side-info-wrapper.print-info {
  border-right: 0;
  padding: 0;
}

.cover-splitpane {
  display: flex;
  height: 20%;

  @media print {
    margin-top: $print-summary-margin;
  }
}

.cover-splitpane-column-spacer {
  width: $spacing-medium;
}

.cover-splitpane-column {
  width: 50%;
  display: block;
}

