@import "../../Style/Config.scss";
@import "../../Style/Config/BorderSizes";

$border-grey: #efeeef;
$border-blue: $color-blue;
$background-grey: #f6f6f6;

.cookie-modal.ihp3-modal {
  & .modal-content-container {
    padding: 0;
  }
}

.cookie-modal-content {
  &-top {
    border-bottom: $border-default-width $border-grey solid;
    padding: 1.5rem;
    display: flex;
  }

  &-title {
    flex-grow: 1;
  }

  &-close-button {
    flex-shrink: 1;
  }

  &-middle {
    .cookie-item {
      font-size: $font-size-small;
      margin: 1.5rem 0;

      & div:nth-child(1) {
        & div:nth-child(2) {
          font-weight: bold;
        }
      }

      &-row {
        display: flex;
        line-height: 1.5;

        & :nth-child(2) {
          flex-basis: 70%;
          flex-grow: 1;
          flex-shrink: 0;
        }

        &>div {
          flex-basis: 30%;
          align-items: start;
          overflow-wrap: break-word;
          justify-content: start;
        }
      }
    }

    &-tabWrapper {
      display: flex;
      flex-direction: row;

      &-tabs {
        width: calc(100% / 3);
        background-color: $background-grey;

        &-tab {
          border-left: 0.4rem solid $background-grey;

          &>button {
            width: 100%;
            padding: 1.3rem 1.5rem;
            padding-right: 2.2rem;
            overflow-wrap: break-word;
            justify-content: start;

            >span {
              font-weight: bold;
              font-size: $font-size-base;
              text-align: left;
              line-height: 1.5;
            }
          }

          &.selected-cookie-title {
            border-left: 0.4rem solid $border-blue;

            &>button {
              background-color: $color-white;
            }
          }
        }
      }

      &-content {
        flex-grow: 1;
        padding: 2rem 3rem;
        height: 530px;
        overflow-y: scroll;
        width: 40vw;

        &-header {
          display: flex;

          &>h3 {
            flex-grow: 1;
            padding-right: 1rem;
            overflow-wrap: break-word;
          }

          &-actionable {
            justify-self: flex-end;
            text-align: right;
            flex-shrink: 1;
            font-size: $font-size-small;

            &-alwaysactive {
              color: $border-blue;
              padding-top: 6px;
              font-weight: bold;
            }
          }
        }

        &-body {
          margin: 1.5rem 0;
          font-size: $font-size-small;
          padding-right: 6rem;
          overflow-wrap: break-word;
          white-space: pre-wrap;

        }

        &>h3 {
          line-height: 4;
          font-size: $font-size-base;
        }

        &>p {
          font-size: $font-size-small;
        }
      }
    }

    &-cookietable {
      &-headers {
        display: flex;
        margin-top: 3rem;

        & button>span {
          font-weight: bold;
          padding-bottom: 0.5rem;
          text-align: left;
        }

        & .selected-cookietable-header {
          border-bottom: $border-blue 2px solid;
        }
      }

      &-header {
        overflow-wrap: break-word;
        border-bottom: $border-grey 2px solid;
        font-size: $font-size-small;

        &+& {
          margin-left: 1rem;
        }
      }
    }
  }

  &-bottom {
    border-top: $border-default-width $border-grey solid;
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-end;

    &>button+button {
      margin-left: 1rem;
    }
  }
}