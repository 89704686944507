@keyframes fadeOut {

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
