@import "../../Style/Config.scss";

$snapshotWidth: 40%;

@mixin ArrowMargin($value) {
  margin-left: $value;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: $value
  }
}

.project-page {
  justify-content: center;
  margin-left: -$spacing-small;
  margin-right: -$spacing-small;

  @include breakpoint(medium-from) {
    max-width: 1100px;
    margin-left: -$spacing-medium;
    margin-right: -$spacing-medium;
  }

  @include breakpoint(x-large-from) {
    max-width: 1100px;
    margin-left: -$spacing-large;
    margin-right: -$spacing-large;
  }
}

.share-project {
  margin-top: -$spacing-medium;
  width: 100%;

  @include breakpoint(large-from) {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }

  @include breakpoint(x-large-from) {
    margin: 0 $spacing-large;
  }

  &-locked {
    .triangle-up {
      // Margin to the arrow when project is Locked
      $marginLockedValue: 54px;

      @include ArrowMargin($marginLockedValue);

      @include breakpoint(medium-from) {
        $marginLockedValue: calc(56% + #{$spacing-large + $spacing-extraSmall});
        @include ArrowMargin($marginLockedValue);
      }

      @include breakpoint(large-from) {
        $marginLockedValue: calc(73% + #{$spacing-small});
        @include ArrowMargin($marginLockedValue);
      }

      @include breakpoint(x-large-from) {
        $marginLockedValue: calc(69% + #{$spacing-large + $spacing-extraSmall});
        @include ArrowMargin($marginLockedValue);
      }
    }
  }
}

.share-project_content {
  background-color: $color-offWhite;
  width: 100%;
  padding: $spacing-small $spacing-small $spacing-large;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .share-project_content_information {
    align-self: center;
  }
}

.triangle-up {
  margin-top: $spacing-tiny;
  width: 0;
  height: 0;
  border-left: $spacing-small solid transparent;
  border-right: $spacing-small solid transparent;
  border-bottom: $spacing-small solid $color-offWhite;

  // Margin to the arrow when project is unlocked
  $marginValue: 105px;

  @include ArrowMargin($marginValue);

  @include breakpoint(medium-from) {
    $marginValue: calc(56% + #{$spacing-large * 2});
    @include ArrowMargin($marginValue);
  }

  @include breakpoint(large-from) {
    $marginValue: calc(73% + #{$spacing-medium * 2});
    @include ArrowMargin($marginValue);
  }

  @include breakpoint(x-large-from) {
    $marginValue: calc(69% + #{$spacing-large * 2});
    @include ArrowMargin($marginValue);
  }
}

.project-content-wrapper {
  background-color: white;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.snapshot-wrapper {
  flex: 1 1 auto;
  width: calc(100% - #{$spacing-small  * 2});
  margin-left: $spacing-small;
  margin-right: $spacing-small;
  margin-bottom: $spacing-medium;

  @include breakpoint(medium-from) {
    margin-bottom: $spacing-small;
  }

  @include breakpoint(large-from) {
    width: calc(#{$snapshotWidth} - #{$spacing-medium  * 2});
    margin-left: $spacing-medium;
    margin-right: $spacing-small;

    [dir="rtl"] & {
      margin-left: $spacing-small;
      margin-right: $spacing-medium;
    }
  }

  @include breakpoint(x-large-from) {
    margin-left: $spacing-large;
    margin-right: 0;
    margin-bottom: 0;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: $spacing-large;
    }
  }
}

.project-information-wrapper {
  flex: 1 1 100%;
  margin-left: $spacing-small;
  margin-right: $spacing-small;
  margin-bottom: $spacing-medium;
  width: 100%;
  hyphens: auto;

  @include breakpoint(large-from) {
    flex: 1;
    margin-right: $spacing-medium;
    margin-left: $spacing-medium;
  }

  @include breakpoint(x-large-from) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    margin-right: $spacing-large;
    margin-left: $spacing-large;
    margin-bottom: 0;

    /* Project information should be vertically centered on height of preview image
    * If project is containing hq renderings, it means that container for Gallery will increase
    * so we need to add margin bottom for the project information, to have the same value as height of the slider.
    */
    &.projectContainsSlideshow {
      margin-bottom: 82px;
    }
  }
}