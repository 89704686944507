@import "../../../Style/_Config.scss";

@import "../PrintConstants.scss";

// Make footer relative to avoid content overlap
.footer-print {
  position: relative;
  clear: both;

  @media print {
    right: 0;
    left: 0;
    margin-top: 15mm;
    page-break-inside: avoid;
  }
}

.footer-projectInfoRow {
  line-height: 9px;

  @include print-text-extraSmall;

  @include line-spacing-print-normal;

  span:last-child {
    font-weight: bold;
  }
}

.footer-projectInfo {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .footer-logo {
    align-self: flex-end;
    flex: 1;
    margin-left: $spacing-medium;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: $spacing-medium;
    }
  }
}

.footer-copyright {
  margin-top: $spacing-small;

  @include print-text-extraSmall;
}

.footer-component {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-small;

  @include print-text-small;

  @media print {
    position: relative;
    padding-right: 0;
  }
}
