@import "../../../../Style/Config.scss";

@import "../../PrintConstants.scss";

// Common style for all print preview pages
.page {
  background: white;
  margin: 25px auto;
  padding: $spacing-medium;

  @media print {
    position: relative;
    width: 100%;
  }
}

// Change to height auto for appropriate styling for both A4 and Letter
.page-portrait {
  position: relative;
  width: $page-width-portrait;
  min-height: $page-height-portrait;

  @include print-preview-page;

  @media print {
    margin: 0;
    width: 100%;
    padding: 0;
    height: auto;
    page-break-after: always;
  }
}

.print-image-frame {
  height: $image-frame-height;
  width: 100%;

  @media print {
    position: relative;
    top: 50%;
    margin-top: $print-header-height;
    height: $image-frame-height-print;
    display: inline-block;
  }
}

.print-image {
  height: 100%;
  margin: 0 auto;
  display: block;

  @media print {
    height: $image-frame-height-print;
    display: inline-block;
    top: -50%;
  }
}

.page-footer {
  @media print {
    margin-top: 20mm;
    page-break-inside: avoid;
  }
}

.hide-on-print {
  @media print {
    display: none;
  }
}

.page-portrait-ie {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media print {
    height: 100vh;
    break-after: page;
  }

  .page-content,
  .page-footer {
    flex: 0 0 auto;
    width: 100%;
  }
}
