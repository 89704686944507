.print-preview {
  /* When parent is having font size in px, child content will not change em sizes when
  changing browser's font size. Print preview should not react to font size changes because
  it should be looking like print output as much as possible. */
  font-size: 15px;
}

.print-preview .small-print p {
  font-size: .65em;
}
