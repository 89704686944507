@import "../Style/Config.scss";

h4,
h5,
h6 {
  font-weight: bold;
}

h4 {
  font-size: 1.154rem;
  line-height: 1.3;
}

h5 {
  font-size: 1.1rem;
  line-height: 1.25;
}

h6 {
  font-size: 1.05rem;
  line-height: 1.2;
}

.lead {
  font-size: 1.308rem;
  line-height: 1.24;
}

small {
  font-size: .8em; /* Use em to make the font-size smaller relative to it's parent */
}

strong {
  font-weight: bold;
}

.text-light-gray {
  color: $color-midDarkGray;
}

@include breakpoint(large-from) {

  h4 {
    font-size: 1.154rem;
  }

  h5 {
    font-size: 1.12rem;
  }

  h6 {
    font-size: 1.1rem;
  }
}

@include breakpoint(x-large-from) {

  h4 {
    font-size: 1.308rem;
  }

  h5 {
    font-size: 1.28rem;
  }

  h6 {
    font-size: 1.2rem;
  }
}
