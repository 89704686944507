@import "../../Style/Config.scss";

.price-breakdown-modal {

  .modal-content-container {
    min-width: 500px;
    max-width: 600px;
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }

  .price-info-wrapper {
    display: flex;
    flex-flow: column;
  }
}

.price-breakdown-modal-wide .modal-content-container {
  width: 665px;
}

.price-info-header {
  margin: 0 auto;
  width: 75%;
  text-align: center;
  margin-bottom: $spacing-small * 2;
}

.price-breakdown-footer {
  margin-top: $spacing-small * 2;
  margin-bottom: $spacing-small;
  color: $color-lightGray;

  @include small;
}

.price-breakdown-article-group .expandable-content {
  @include small;
}
