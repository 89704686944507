[class*="ratio-"] {
  position: relative;
  overflow: hidden;
}

[class*="ratio-"] > iframe,
[class*="ratio-"] > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important; /* stylelint-disable-line */
  height: 100% !important; /* stylelint-disable-line */
}

.ratio-16-9::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: calc(9/16 * 100%);
}

.ratio-4-3::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: calc(3/4 * 100%);
}
