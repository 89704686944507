@import "../../../Style/Config.scss";

.project-page-rename .form-control + .form-control {
  margin-top: $spacing-small;
}

.project-page-rename .form-control input[type="text"] { /* stylelint-disable-line selector-no-qualifying-type */
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  font-size: 1.625rem;
  font-weight: 700;
  height: $spacing-large;
  width: 100%;
  padding-top: $spacing-extraSmall;
  padding-bottom: $spacing-extraSmall;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

.project-page-rename .form-control input[type="text"]:focus { /* stylelint-disable-line selector-no-qualifying-type */
  border: 1px solid #b2b2b2;
}

.project-page-rename .form-control textarea {
  width: 100%;
  padding: $spacing-extraSmall;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  resize: none;
}

.project-page-rename .form-control textarea:focus {
  border: 1px solid #b2b2b2;
}

.project-page-rename .control-buttons {
  display: flex;
  justify-content: flex-end;
}

.project-page-rename .control-buttons .ihp3-button + .ihp3-button {
  margin-left: $spacing-extraSmall;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: $spacing-extraSmall;
  }
}

.project-page-rename .small-print {
  color: $color-midDarkGray;
  font-size: .75rem;
  padding: $spacing-extraSmall;
}

