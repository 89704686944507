$level-top: 99999;
$level-10: 10000;
$level-9: 9000;
$level-8: 8000;
$level-7: 7000;
$level-6: 6000;
$level-5: 5000;
$level-4: 4000;
$level-3: 3000;
$level-2: 2000;
$level-1: 1000;
$level-bottom: 1;
$level-minus-1: -1000;
$level-minus-2: -2000;
