@import "../../Style/Config.scss";

$modalWindowWidth: 960px;

.special-product-wrapper {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(x-large-from) {
    width: $modalWindowWidth;
  }
}

.special-product-image {
  text-align: center;
  padding-right: $spacing-small;
  width: 55%;

  img {
    display: inline-block;
    width: 75%;
    height: auto;
  }

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $spacing-small;
  }
}

.special-product-information {
  padding-left: $spacing-small;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  [dir="rtl"] & {
    padding-right: $spacing-small;
    padding-left: 0;
  }
}
