@import "../../Style/_Config.scss";

@import "../../Style/_Animations.scss";

$loading-screen-height: 90vh;
$item-list-max-width: 1090px;
$bottom-container-animation-duration: 300ms;

.itemList-loading-screen {
  height: $loading-screen-height;
  width: $item-list-max-width;
}

.itemList-container {
  position: relative;
  width: auto;
  background-color: $color-white;
}

@include breakpoint(large-from) {

  .itemList-frame-content {
    padding-right: $spacing-small;
    padding-left: $spacing-small;
  }

  .itemList-container {
    max-width: $item-list-max-width;
  }
}

.itemList-bottom-container {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $level-top;
  background-color: $color-white;
  padding-top: $spacing-extraSmall;
  padding-bottom: $spacing-extraSmall;
  animation: fadeInUp ease-out $bottom-container-animation-duration;

  .itemList-action-button {
    position: relative;
    float: right;
    margin-right: $spacing-small;

    [dir="rtl"] & {
      float: left;
      margin-right: auto;
      margin-left: $spacing-small;
    }
  }
}

.itemList-error-container {
  margin-top: $spacing-huge;
  margin-bottom: $spacing-huge;
  margin-right: $spacing-large;
  margin-left: $spacing-large;
}

.itemList-container .itemList-section-header {
  flex-direction: column;
}

.itemList-container .footer-print {
  position: relative;
  right: auto;
  bottom: auto;
  left: auto;
}

.itemList-container .page-portrait {
  width: auto;
  height: auto;
}

.ihp3-modal.item-list-modal {

  .modal-container {
    max-height: 100vh;
  }

  .modal-content-container {
    padding: 0;
    margin-bottom: 60px;
  }
}

.ihp3-modal.item-list-modal .modal-content-container > .button-close-icon {
  position: absolute;
  top: $spacing-extraSmall;
  right: $spacing-extraSmall;

  [dir="rtl"] & {
    right: auto;
    left: $spacing-extraSmall;
  }
}
