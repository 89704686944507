.ihp3-modal.bottom-aligned-container {
  .modal-container {
    padding: 0;
    justify-content: center;
    overflow: hidden;
  }

  .modal-content-container {
    padding: 0;
    margin-bottom: 0;
    width: 80%;
  }

  .modal-bottom-spacer {
    height: 0;
  }
}