.text-center {
  text-align: center;
}

.display-none {
  display: none;
}

.cover-image {
  width: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.fill-image {
  width: 100%;
  object-fit: fill;
  font-family: "object-fit: fill;";
}

.contain-image {
  width: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.scale-down-image {
  width: 100%;
  object-fit: scale-down;
  font-family: "object-fit: scale-down;";
}
