$list-column-priceTotal-width: 60px;
$list-column-amount-width: 45px;
$list-column-price-width: 60px;
$list-column-article-width: 60px;

$list-column-print-width-factor: 1.5;

@mixin list-column-sizing($baseWidth) {
  width: $baseWidth;

  @media print {
    width: #{$baseWidth * $list-column-print-width-factor};
  }
}

@mixin list-column-priceTotal {
  @include list-column-sizing($list-column-priceTotal-width);
}

@mixin list-column-amount {
  @include list-column-sizing($list-column-amount-width);
}

@mixin list-column-price {
  @include list-column-sizing($list-column-price-width);
}

@mixin list-column-article {
  @include list-column-sizing($list-column-article-width);
}
