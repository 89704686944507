@import "../../Style/Config.scss";

$maxViewWidth: 100vw;
$project-container-border-size: 1px;

$cards-animation-duration: 500ms;
$cards-step-delay: 200ms;

@keyframes slideInFromLeft {

  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes customFadeIn {

  0% { opacity: 0; }

  100% { opacity: 1; }
}

.projectList-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.projectList-heading {
  position: relative;
  text-align: center;
}

.kiosk-mode {

  .projectList-container,
  .projectList-heading {
    height: 100%;
    flex-basis: 1%;
    justify-content: start;
    flex-grow: 1;
  }
}

.projectList-heading-image {
  display: none;

  @include breakpoint(medium-from) {
    display: block;
    height: 300px;
    box-shadow: inset 0 0 0 $maxViewWidth rgba(0,0,0,.1);
    background-size: cover;
    background-position: center;
  }
}

.projectList-heading-image .page-headline {
  text-align: center;
}

.tablet-desktop-header {
  display: none;
  color: $color-white;
  text-shadow: 0px 2px $spacing-extraSmall $color-textBlack;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint(medium-from) {
    display: block;
  }
}

.mobile-header {
  color: $color-textBlack;

  @include breakpoint(medium-from) {
    display: none;
  }
}

.projectList-searchInfo {
  text-align: center;
}

.projectList-searchInfo h2 {
  padding-top: $spacing-large;
  padding-bottom: $spacing-extraSmall;
}

.locked-project-message {
  padding-top: $spacing-medium;
  text-align: center;
  margin: 0 auto;
  width: 80%;

  @include paragraph;

  @include breakpoint(medium-from) {
    width: 60%;
  }
}

.locked-project-message span {
  font-weight: bold;
}

.projectList-gutter {
  border-bottom: $project-container-border-size solid $color-lightGray;
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
  margin-left: $spacing-extraSmall;
  margin-right: $spacing-extraSmall;

  @include breakpoint(medium-from) {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }

  @include breakpoint(x-large-from) {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
}

.projectList-cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @include breakpoint(large-from) {
    margin-top: $spacing-small * 2;
  }
}

.projectList-card-container {
  flex: 0 0 auto;
  flex-basis: calc( 100% - #{$spacing-small});
  flex-basis: 100%;
  box-sizing: content-box;
  overflow-y: hidden;
  opacity: 0;

  @for $i from 1 through 8 {

    &:nth-child(#{$i}n) {
      animation-duration: $cards-animation-duration;
      animation-delay: #{$i * $cards-step-delay};
    }
  }

  @include breakpoint(medium-from) {
    margin-right: $spacing-extraSmall;
    margin-left: $spacing-extraSmall;
    flex-basis: calc( 100% / 2 - #{$spacing-small});
  }

  @include breakpoint(large-from) {
    flex-basis: calc( (100% / 3) - #{$spacing-small});
  }

  @include breakpoint(x-large-from) {
    flex-basis: calc( (100% / 4) - #{$spacing-small});
  }

  &.instant-animations {
    // Safari would not play the animation at all with a 0s,
    // causing the animation to be stuck at the start state instead of the end state
    animation-duration: 1ms;
    animation-delay: 0s;
  }

  [dir="rtl"] .button.projectList-logout-button {
    float: left;
  }

  .button.projectList-logout-button {
    float: right;
    color: $color-midDarkGray;
  }

  .projectList-empty-container {
    padding: 40px;
    background-color: $color-offWhite;
    height: 100vh;
    width: 100vw;
  }

  .projectList-empty {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: auto;
    flex-direction: column;
    max-width: 800px;
  }

  .projectList-empty-image {
    margin-bottom: $spacing-small;
    max-width: 100%;
  }

  .projectList-empty-content-container {
    max-width: 430px;
    margin: auto;
  }

  .projectList-empty-content {
    margin-bottom: 36px;
    font-weight: bold;
  }

  li {
    font-size: 14px;
  }
}

.projectList-pagination-container {
  margin-top: $spacing-small;
  padding-top: $spacing-small;
  display: flex;
  justify-content: center;
  color: $color-textBlack;
}

.projectList-pagination-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: transparent;

  button {
    border: 0;
    cursor: pointer;
  }

  li {
    font-size: 14px;
  }
}

.projectList-pagination-button-back {
  background-color: transparent;
}

.projectList-pagination-button-back::before {
  display: inline-block;
  font-family: $icon-font-name;
  content: $icon-caret-left;
}

[dir="rtl"] .projectList-pagination-button-back::before {
  content: $icon-caret-right;
}

.projectList-pagination-button-forward {
  background-color: transparent;
}

.projectList-pagination-button-forward::before {
  display: inline-block;
  font-family: $icon-font-name;
  content: $icon-caret-right;
}

[dir="rtl"] .projectList-pagination-button-forward::before {
  content: $icon-caret-left;
}

.projectList-pagination-list {
  display: flex;
  flex-direction: row;
}

.projectList-pagination-list li {
  margin-right: $spacing-tiny;
  margin-left: $spacing-tiny;
}

.projectList-pagination-item-selected {
  font-weight: bold;
}

.projectList-pagination-item-selected,
.projectList-pagination-item-clickable:hover {
  border-bottom: thin solid $color-black;
}

.projectList-pagination-item-clickable:hover {
  cursor: pointer;
}

.projectList-pagination-row li button {
  font-size: 14px;
  padding: 0;
}

.empty-filter {
  margin: 0 auto;
  text-align: center;

  @include breakpoint(large-from) {
    width: 700px;
  }
}

.empty-filter-content {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;
}

.empty-filter-buttons {
  display: flex;
  justify-content: center;
}

.empty-filter-buttons .ihp3-button + .ihp3-button {
  margin-left: $spacing-small;

  [dir="rtl"] & {
    margin-right: $spacing-small;
    margin-left: 0;
  }
}
