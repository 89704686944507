@keyframes pushIn {

  from {
    transform: translateX(100%);
  }
}

@keyframes pushOut {

  to {
    transform: translateX(-100%);
  }
}

.animate-push-in {
  animation-name: pushIn;
  animation-duration: $animation-default-push-pop-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.animate-push-out {
  animation-name: pushOut;
  animation-duration: $animation-default-push-pop-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
