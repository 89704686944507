.flex-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.flex-column-divider {
  flex: 0 0 auto;
  width: 1px;
  background-color: #e2e2e2;
  margin-left: 20px;
  margin-right: 20px;
}

.flex-column-equal {
  flex: 1 1 50%;
}

.flex-column-fit-content {
  flex: 1 1 auto;
}
