@import "../../../Style/Config.scss";

$tabMinWidth: 125px;

.tabs-container {

  .tab {
    flex-basis: calc(100%/3);
    color: $color-black;
    text-align: center;
    padding-top: $spacing-extraSmall;
    padding-bottom: $spacing-extraSmall;
    cursor: pointer;

    /* Required to move search input below tabs and expand tabs */
    @media only screen and (min-width: 960px) {

      min-width: $tabMinWidth;
      flex-basis: 0;
    }
  }

  /* justify-content change required to avoid searchbar overlapping if device < 1550px */
  .tabs-content {
    display: flex;
    flex-direction: row;
    justify-content: right;

    [dir="rtl"] & {
      justify-content: left;
    }

    @media only screen and (min-width: 1550px) {
      justify-content: center;
    }
  }

  .tab-active {
    border-top: 1px solid $color-lightGray;
    border-left: 1px solid $color-lightGray;
    border-right: 1px solid $color-lightGray;
    font-weight: bold;
    background: $color-white;
    z-index: 2;
  }

  .tab:hover,
  .tab-active {
    text-decoration: underline;
  }

  &::after {
    display: block;
    content: "";
    background: $color-lightGray;
    height: 1px;
    margin-top: -1px;
  }
}
