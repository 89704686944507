@import "../../../Style/Config.scss";

@import "./ItemListConstants.scss";

@import "./ItemListDigitalConstants.scss";

@import "../PrintConstants.scss";

.itemList-item-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: $spacing-small;
  padding-bottom: $spacing-small;
  border-bottom: 1px solid $color-midDarkGray;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.itemList-item-expired >* {
    color: $color-red;
  }

  .itemList-item-drawings {
    margin-right: $spacing-small;

    img {
      width: 30px;
    }

    [dir="rtl"] & {
      margin-left: $spacing-small;
      margin-right: 0px;
    }
  }

  .itemList-item-data {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    line-height: $print-font-size-medium;

    @include print-text-small;
  }

  .itemList-item-data > :first-child {
    font-weight: bold;
  }

  .itemList-item-data > :not(:last-child) {
    margin-bottom: $spacing-tiny;
  }

  .itemList-item-firstBlock {
    display: flex;
    flex-direction: row;
    width: 200px;

    &--digital {
      width: inherit;
    }

    @media print {
      flex: 1 1 50%;
    }

    // needed for correct text alignment when printing in IE
    // otherwise the art.num. etc have an offset to their headers
    .page-portrait-ie & {
      // stylelint-disable-next-line max-nesting-depth
      @media print {
        flex: 0 1 35%;
      }
    }
  }

  .itemList-item-columns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    line-height: $spacing-extraSmall;
    @include print-text-small;
    width: 220px;

    &--digital {
      width: inherit;
    }

    @media print {
      flex: 1 1 50%;
    }

    > * {
      text-align: end;
      margin-top: auto;
      margin-bottom: auto;
    }

    .itemList-item-articleColumn {
      @include list-column-article;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-article;
      }
    }

    .itemList-item-priceColumn {
      @include list-column-price;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-price;
      }
    }

    .itemList-item-amountColumn {
      @include list-column-amount;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-amount;
      }
    }

    .itemList-item-priceTotalColumn {
      @include list-column-priceTotal;

      // stylelint-disable-next-line max-nesting-depth
      &--digital {
        @include digitalList-column-priceTotal;
      }
    }
  }

  .itemList-item-discountInfo {
    @include line-spacing-print-normal;
  }

  .itemList-item-price-twoRows {
    display: flex;
    flex-direction: column;

    @include line-spacing-print-large;

    & .price-container:first-child {
      margin-bottom: 1px;
    }

    & .price-container:last-child {
      margin-top: 1px;
    }
  }

  .itemList-item-disclaimer-notice {
    line-height: $print-font-size-small;
    font-size: $print-font-size-extraSmall;

    &.temporary {
      text-decoration: line-through;
      div {
        text-decoration: line-through;
      }
    }

    &--digital {
      line-height: $print-font-size-large;
      font-size: $font-size-base;
    }
  }
}
