@use "sass:math";

@import "../Style/Config.scss";

$launchPageBreakpointLarge: 1680px;
$header-height: 56px;

@include breakpoint(medium-from) {

  .hero-buttons  .ihp3-button {
    white-space: nowrap;
  }
}

@mixin launchPageBreakpointLargeFrom() {
  @media screen and (min-width: $launchPageBreakpointLarge) { @content ; }
}

@keyframes fadein {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.launchpage-hero {

  .hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    // stylelint-disable-next-line max-nesting-depth
    @include breakpoint(large-from) {
      width: 30%;
    }
  }

  .hero-buttons  .ihp3-button {
    padding-left: math.div($spacing-small, 2);
    padding-right: math.div($spacing-small, 2);

    // stylelint-disable-next-line max-nesting-depth
    @include breakpoint(medium-from) {
      flex: 1 1 50%;
    }
  }
}

.page-footer .footer-services .servicesSection {

  background-color: $color-white;

  @include breakpoint(large-from) {
    flex: 1 1 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    padding-left: 0;
    padding-right: 0;
  }

  h2,
  h3,
  p:not(ul) {
    display: none;
  }
}


.page-footer .footer-services .servicesSection {
  background-color: $color-white;
  padding: 0;

  @include breakpoint(large-from) {
    flex: 1 1 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    padding-left: 0;
    padding-right: 0;
  }

  h2,
  h3,
  p:not(ul) {
    display: none;
  }
}

.page-footer .footer-services .servicesSection .servicesSection-servicesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $color-lightGray;
  padding-top: $spacing-small;
  margin-bottom: 0;
  margin-left: $spacing-extraSmall;
  margin-right: $spacing-extraSmall;

  @include breakpoint(medium-from) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }

  @include breakpoint(x-large-from) {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
}

.page-footer .footer-services .servicesSection .servicesSection-servicesList li {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 180px;
  width: 200px;
  margin: 0;

  @include breakpoint(medium-from) {

    flex-direction: column;

  }

  span {
    @include paragraph;
  }

}

.page-footer .footer-services .servicesSection .servicesSection-servicesList li :nth-child(3n+2) {
  margin-left: $spacing-small;
  margin-right: $spacing-small;
}

.page-footer .footer-services .servicesSection .servicesSection-servicesList li .service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;

  @include breakpoint(medium-from);
}

.page-footer .footer-services .servicesSection .servicesSection-servicesList li .service:hover {
  border: 2px solid $color-blue;
  border-radius: 2px;
}

.page-footer .footer-services .servicesSection .servicesSection-servicesList li .service-icon {
  display: inline-flex;
  margin: 0;

  @include breakpoint(medium-from) {
    display: flex;
    justify-content: center;
    height: $spacing-large;
    width: 100%;
    margin: 0;
  }

}

.page-footer .footer-services .servicesSection .servicesSection-servicesList li .service-icon img {
  height: 90px;
  padding-bottom: $spacing-medium;
  margin-bottom: $spacing-medium;
  width: 90px;

  @include breakpoint(medium-from) {
    margin-bottom: 0;
  }
}
