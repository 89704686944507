@import "../../Style/Config.scss";

$print-footer-max-height: 16%; // Height of footer with disclaimer + projectinfo
$print-header-height: 10%;
$image-frame-height: 595px;
$image-frame-height-print: 675px;

// Shared constants for summary page components
$print-summary-margin: 8%;

// Shared constants for the PrintSideInfo classes
$print-side-background: white;
$print-side-hover-indicator: $color-offWhite;
$print-side-separator-color: $color-lightGray;

// Shared constants for print components that display a 'fake loading' state.
// This duration needs to be kept in sync with FAKE_LOADING_TRANSITION_MS in Print.jsx.
$fakeloading-animate-duration: .35s;

@mixin line-spacing-print-normal {
  @media print {
    line-height: 8pt;
  }
}

@mixin line-spacing-print-large {
  @media print {
    line-height: 12pt;
  }
}
