.language-modal.ihp3-modal {
  & .modal-content-container {
    padding: 1.5rem;
    position: absolute;
  }
}

.language-modal-label {
  padding-bottom: 1.5rem;
}

.language-modal-buttons {
  display: flex;
  justify-content: center;
}