@import "../../../Style/Config.scss";

$deletionErrorAnimationOffset: 20px;

.user-control-content {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: $spacing-extraSmall;

  button {
    margin-right: $spacing-small;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: $spacing-small;
    }
  }
}

.delete-confirmation-modal {
  text-align: center;
  transition: all 100ms;

  h3 {
    margin-bottom: $spacing-small;
  }

  button {
    margin-top: $spacing-small;
  }

  button + button {
    margin-left: $spacing-medium;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: $spacing-medium;
    }
  }
}

/* stylelint-disable */
#confirm-delete-project-modal .modal-content-container {
  /* stylelint-enable */
  overflow: hidden;
}

button.action-deleteProject,
button.action-printProject,
button.action-shareProject,
button.icon-edit {
  color: $color-black;
}